@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vis-item {
  border-color: #85929E !important;
}

.vis-item.visItemColor0, vis-nested-group.visGroupColor0 {
  background-color: #EF9A9A !important
}
.vis-item.visItemColor1, .vis-nested-group.visGroupColor1 {
  background-color: #F8BBD0 !important
}
.vis-item.visItemColor2, .vis-nested-group.visGroupColor2  {
  background-color: #E1BEE7 !important
}
.vis-item.visItemColor3, .vis-nested-group.visGroupColor3  {
  background-color: #D1C4E9 !important
}
.vis-item.visItemColor4, .vis-nested-group.visGroupColor4 {
  background-color: #C5CAE9 !important
}
.vis-item.visItemColor5, .vis-nested-group.visGroupColor5  {
  background-color: #BBDEFB !important
}
.vis-item.visItemColor6, .vis-nested-group.visGroupColor6  {
  background-color: #B2EBF2 !important
}
.vis-item.visItemColor7, .vis-nested-group.visGroupColor7  {
  background-color: #B2DFDB !important
}
.vis-item.visItemColor8, .vis-nested-group.visGroupColor8  {
  background-color: #C8E6C9 !important
}
.vis-item.visItemColor9, .vis-nested-group.visGroupColor9  {
  background-color: #DCEDC8 !important
}
.vis-item.visItemColor10, .vis-nested-group.visGroupColor10  {
  background-color: #F0F4C3 !important
}
.vis-item.visItemColor11, .vis-nested-group.visGroupColor11  {
  background-color: #FFF9C4 !important
}
.vis-item.visItemColor12, .vis-nested-group.visGroupColor12 {
  background-color: #FFECB3 !important
}
.vis-item.visItemColor13, .vis-nested-group.visGroupColor13 {
  background-color: #FFE0B2 !important
}
.vis-item.visItemColor14, .vis-nested-group.visGroupColor14 {
  background-color: #FFCCBC !important
}
.vis-item.visItemColor15, .vis-nested-group.visGroupColor15 {
  background-color: #CFD8DC !important
}
.vis-item .vis-item-content {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.hide {
  display: none;
}

.show {
  display: initial;
}

#operation {
  font-size: 28px;
}
#node-popUp {
  display: none;
  position: absolute;
  top: 350px;
  left: 170px;
  z-index: 299;
  width: 250px;
  height: 120px;
  background-color: #f9f9f9;
  border-style: solid;
  border-width: 3px;
  border-color: #5394ed;
  padding: 10px;
  text-align: center;
}
#edge-popUp {
  display: none;
  position: absolute;
  top: 350px;
  left: 170px;
  z-index: 299;
  width: 250px;
  height: 90px;
  background-color: #f9f9f9;
  border-style: solid;
  border-width: 3px;
  border-color: #5394ed;
  padding: 10px;
  text-align: center;
}


.snow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(imgs/themes/christmas/snow.png);
  background-repeat: repeat;
  -webkit-animation: snowfall01 20s linear infinite;
  animation: snowfall01 20s linear infinite;
  pointer-events: none;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
.snow::before {
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(imgs/themes/christmas/snow.png);
  background-repeat: repeat;
  content: '';
  -webkit-animation: snowfall02 20s linear infinite;
  animation: snowfall02 20s linear infinite;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
.snow::after {
  opacity: .4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(imgs/themes/christmas/snow.png);
  background-repeat: repeat;
  content: '';
  -webkit-animation: snowfall03 20s linear infinite;
  animation: snowfall03 20s linear infinite;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
@-webkit-keyframes snowfall01 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 150px 600px;
  }
}
@-webkit-keyframes snowfall02 {
  0% {
    background-position: 0 -200px;
  }
  100% {
    background-position: 0 600px;
  }
}
@-webkit-keyframes snowfall03 {
  0% {
    background-position: 0 200px;
  }
  100% {
    background-position: 300px 600px;
  }
}
@keyframes snowfall01 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 150px 600px;
  }
}
@keyframes snowfall02 {
  0% {
    background-position: 0 -200px;
  }
  100% {
    background-position: 0 600px;
  }
}
@keyframes snowfall03 {
  0% {
    background-position: 0 200px;
  }
  100% {
    background-position: 300px 600px;
  }
}